<template>
  <v-row justify="center">
    <v-dialog
      scrollable
      v-model="show"
      max-width="1000px"
      @click:outside="init()"
      :persistent="persistent"
    >
      <v-card>
        <v-card-title class="d-flex align-center pt-6 d-flex justify-space-between">
          <div class="text-h5 d-flex align-start">
            {{ titleText }}
            <v-tooltip
              top
              color="black"
              v-if="boardNo === 0"
            >
              <template #activator="{ on, attrs }">
                <v-icon
                  class="question ml-1"
                  small
                  color="warning"
                  v-on="on"
                  v-bind="attrs"
                >mdi-help-circle-outline</v-icon>
              </template>
              <span>
                가장 최근에 등록한 공지사항은 대시보드(메인화면)에 표기되며,<br />
                우측 '전체알림' 체크시 모든 직원(퇴사 제외)에게 알림이 발송됩니다.
              </span>
            </v-tooltip>
          </div>
          <div class="d-flex top-info-wrap align-center">
            <div
              class="item search-picker-box"
              v-if="type === 0"
            >
              <v-btn
                depressed
                class="pr-3 pl-3 item"
                v-if="customerData.id === null"
                color="info"
                outlined
                @click="customerPicker = true"
              >
                고객 선택
              </v-btn>
              <v-chip
                v-else
                outlined
                class="item"
                chip
                color="info"
                @click="customerPicker = true"
              >
                {{`
                  ${customerData.name}
                  (${customerData.phone.slice(-4)})
                `}}
              </v-chip>
              <v-card
                class="search-picker"
                v-click-outside="customerPickerInit"
                v-if="customerPicker === true"
              >
                <v-text-field
                  :autofocus="true"
                  dense
                  flat
                  hide-details
                  v-model="customerText"
                  @keydown.enter="getCustomerList()"
                  placeholder="고객명/전화번호 입력 후 엔터"
                  color="black"
                  outlined
                  solo
                ></v-text-field>
                <v-list v-if="customers.length > 0">
                  <v-list-item
                    v-for="(row, i) in customers"
                    :key="i"
                    dense
                    nav
                    link
                    @click="customerSetter(row)"
                  >
                    <v-list-item-title>
                      <v-chip
                        small
                        class="pr-1 pl-1 mr-1"
                        link
                        outlined
                        label
                      >
                        {{comm.makePrivatePhoneNumber(row.phone)}}
                      </v-chip>
                      {{ row.name }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
                <v-list v-else-if="customers.length === 0">
                  <v-list-item dense>
                    <v-list-item-title>검색어 입력 후 엔터를 눌러주세요.</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card>
            </div>
            <v-checkbox
              v-else-if="boardNo === 0"
              v-model="allNotification"
              label="전체알림"
              color="success"
              class="mt-2 pb-4 mr-4"
              hide-details
            />
            <div class="item search-picker-box">
              <div class="item">
                <v-btn
                  v-if="staffData.id === null"
                  @click="getStaffsList()"
                  depressed
                  class="pr-3 pl-3 item"
                  color="primary"
                  outlined
                >{{ type === 0 ? '영업담당자 선택' : '수신인 선택' }}</v-btn>
                <v-chip
                  outlined
                  class="item"
                  v-else
                  color="primary"
                  @click="getStaffsList()"
                >{{ staffData.name }}</v-chip>
              </div>
              <v-card
                class="search-picker"
                v-click-outside="staffPickerInit"
                v-if="staffPicker === true"
              >
                <v-text-field
                  :autofocus="true"
                  dense
                  flat
                  hide-details
                  v-model="staffText"
                  :placeholder="type === 0 ? '담당자 검색' : '수신인 검색'"
                  @input="staffsFiltering"
                  color="black"
                  outlined
                  solo
                ></v-text-field>
                <v-list v-if="staffsFilter.length > 0">
                  <v-list-item
                    v-for="(row, i) in staffsFilter"
                    :key="i"
                    dense
                    nav
                    link
                    @click="
                      staffData = {
                        name: `${row.group} ${row.name}`,
                        id: row.id,
                      };
                      staffPickerInit();
                    "
                  >
                    <v-list-item-title>
                      <v-chip
                        small
                        class="pr-1 pl-1 mr-1"
                        link
                        outlined
                        label
                      >
                        {{ row.group }}
                      </v-chip>
                      {{ row.name }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
                <v-list v-else-if="staffsFilter.length === 0">
                  <v-list-item dense>
                    <v-list-item-title>{{
                      staffText == null
                      || staffText.trim().length === 0
                      ?'검색어를 입력해주세요.'
                      :'데이터가 없습니다.'
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card>
            </div>
          </div>
        </v-card-title>
        <div class="content-wrap">
          <v-text-field
            flat
            solo
            dense
            hide-details
            outlined
            color="#555"
            autofocus
            placeholder="제목"
            class="mt-3 mb-3"
            v-model="titleData"
          />
          <editor
            ref="editor"
            initialValue=""
            height="500px"
            initialEditType="wysiwyg"
            :options="{
              language: 'ko',
            }"
          />
        </div>
        <div class="files-wrap">
          <div class="chip-group">
            <v-chip
              v-for="(row, index) in fileList"
              :key="index"
              close
              class="mr-1 mt-1 mb-1 ml-1"
              @click:close="fileRemove(row.name)"
            >
              <strong>{{ row.name }}</strong>
              <span>({{ fileUtils.byteTrans(row.size) }})</span>
            </v-chip>
          </div>
          <input
            type="file"
            class="file-input"
            ref="fileInput"
            @change="fileChange"
            multiple
          />
          <v-btn
            depressed
            color="info"
            class="pl-2 pr-2"
            @click="fileClick"
            v-if="fileList.length === 0"
          >
            <v-icon class="mr-1">mdi-cloud-upload-outline</v-icon>
            파일첨부
          </v-btn>
        </div>
        <v-card-actions class="pb-6 pr-6 pt-6">
          <v-spacer></v-spacer>
          <v-btn
            @click="regist()"
            color="primary"
            depressed
            v-if="type === 0"
            :disabled="titleData === null || titleData.trim() === '' || customerData.id === null
              || staffData.id === null"
          >
            {{ btnText }}
          </v-btn>
          <v-btn
            @click="regist()"
            color="primary"
            depressed
            v-else
            :disabled="titleData === null || titleData.trim() === ''"
          >
            {{ btnText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapMutations, mapActions } from 'vuex';
import '@toast-ui/editor/dist/toastui-editor.css';
import '@toast-ui/editor/dist/i18n/ko-kr';
import { Editor } from '@toast-ui/vue-editor';
import comm from '@/util/comm';
import fileUtils from '@/util/file';

export default {
  components: {
    Editor,
  },
  name: 'DlgPostRegist',
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    func: {
      type: Function,
      default: () => {},
    },
    customer: {
      type: String,
    },
    manager: {
      type: String,
    },
    title: {
      type: String,
    },
    type: {
      type: Number,
      required: true,
    },
    boardNo: {
      type: Number,
    },
  },
  data: () => ({
    comm,
    persistent: true,
    fileUtils,
    groups: [],
    staffText: null,
    staffs: [],
    staffsFilter: [],
    staffPicker: [],
    customerText: null,
    customers: [],
    customerPicker: false,
    titleData: null,
    contentData: null,
    customerData: {
      name: null,
      id: null,
      managerName: null,
      manager: null,
      managerGroupNo: null,
    },
    staffData: {
      name: null,
      id: null,
    },
    fileList: [],
    allNotification: false,
  }),
  computed: {
    titleText() {
      if (this.type === 0) {
        return '상담등록';
      }
      return '게시글작성';
    },
    btnText() {
      if (this.type === 0 || this.type === 1) {
        return '등록';
      }
      return '수정';
    },
  },
  methods: {
    ...mapMutations({
      progress: 'dialog/progress',
    }),
    ...mapActions({
      alert: 'dialog/alert',
    }),
    init() {
      this.persistent = false;
      this.$emit('update:show', false);
      this.staffData = {
        name: null,
        id: null,
      };
      this.customerData = {
        name: null,
        id: null,
        managerName: null,
        manager: null,
        managerGroupNo: null,
      };
      this.contentData = null;
      this.fileList = [];
      this.titleData = null;
      this.customerPickerInit();
      this.$refs.editor.invoke('reset');
    },
    staffPickerInit() {
      this.staffText = null;
      this.staffs = [];
      this.staffPicker = false;
    },
    customerPickerInit() {
      this.customerText = null;
      this.customers = [];
      this.customerPicker = false;
    },
    customerSetter(customer) {
      this.customerPickerInit();
      const groupName = this.comm.getGroupFullPath(this.groups, customer.managerGroupNo, '그룹없음 ');
      this.staffData = {
        id: customer.manager,
        name: `${groupName}${customer.managerName}`,
      };
      this.customerData = customer;
    },
    getCustomerList() {
      this.customers = [];
      if (this.customerText === null || this.customerText.length <= 0) {
        this.customers = [];
      } else if (this.customerText.length < 2 || this.customerText === '010') {
        this.alert(['error', '2자 이상이며 \'010\'이 아닌 검색어를 입력해주세요.']);
      } else {
        this.progress(true);
        this.$socket.emit('users.customer.list.min.get', {
          page: 1,
          itemsPerPage: 0,
          sortBy: [],
          sortDesc: [],
          filters: [{
            filters: [
              {
                condition: 'inc',
                column: 'name',
                value: this.customerText,
              },
              {
                where: 'or',
                condition: 'inc',
                column: 'phone',
                value: this.customerText,
              },
            ],
          }],
        }, async (resp) => {
          if (resp.result === 'success') {
            this.customers = Object.freeze(JSON.parse(await fileUtils.buffersToJson(resp.items)));
            this.progress(false);
          } else {
            this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
            console.error(resp);
          }
        });
      }
    },
    staffsFiltering(e) {
      if (e != null && e.length > 0) {
        const result = this.staffs.filter((r) => r.fullName.indexOf(e) !== -1);
        this.staffsFilter = result;
      } else {
        this.staffsFilter = [];
      }
    },
    getStaffsList() {
      this.staffs = [];
      this.staffsFilter = [];
      this.staffText = null;
      this.progress(true);
      this.$socket.emit('users.staffs.list.get', {
        page: 1,
        itemsPerPage: 0,
        sortBy: ['groupNo'],
        sortDesc: [false],
        filters: this.type === 0 ? [{ condition: 'eq', column: 'is_sales', value: 1 }] : [],
      }, (resp) => {
        if (resp.result === 'success') {
          resp.items.forEach((r) => {
            const groupName = comm.getGroupFullPath(this.groups, r.groupNo, '그룹없음 ');
            const user = {
              id: r.id,
              fullName: `${groupName}${r.name}`,
              name: r.name,
              group: groupName,
            };
            this.staffs.push(user);
          });
          this.staffsFilter = this.staffs;
          this.progress(false);
          this.staffPicker = true;
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
        }
      });
    },
    getGroupList() {
      this.$socket.emit('groups.list.get', {
        page: 1,
        itemsPerPage: 0,
        sortBy: ['no'],
        sortDesc: [false],
        filters: [],
      }, (resp) => {
        if (resp.result === 'success') {
          this.groups = resp.items.groups;
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
        }
      });
    },
    async fileChange(e) {
      this.progress(true);
      let bufferList = [];
      if (e.target.files.length > 5) {
        this.alert(['error', '첨부파일은 최대 5개까지만 등록가능합니다.']);
      } else if (e.target.files.length > 0) {
        const sizeCheck = Object.values(e.target.files).filter((row) => row.size >= 10485760);
        if (sizeCheck.length === 0) {
          const map = Object.values(e.target.files)
            .map((file) => this.fileUtils.readFileToPayload(file));
          const files = await Promise.all(map);
          bufferList = files;
        } else {
          this.alert(['error', '파일당 최대 10MB 까지 업로드 가능합니다.']);
        }
      }
      this.fileList = bufferList;
      this.progress(false);
    },
    fileClick() {
      this.$refs.fileInput.click();
    },
    fileRemove(name) {
      const filterArr = this.fileList.filter((row) => row.name !== name);
      this.fileList = filterArr;
    },
    regist() {
      this.progress(true);
      this.contentData = this.$refs.editor.invoke('getMarkdown');

      let command = '';
      let reqObj = {};
      let succesMsg = '';
      if (this.type === 0) {
        command = 'cs.add';
        reqObj = {
          manager: this.staffData.id,
          customer: this.customerData.id,
          content: this.contentData,
          files: this.fileList,
          title: this.titleData,
        };
        succesMsg = '상담이 등록되었습니다.';
      } else if (this.type === 1) {
        command = 'board.post.add';
        reqObj = {
          boardNo: this.boardNo,
          tag: this.staffData.id !== null ? [this.staffData.id] : [],
          content: this.contentData,
          files: this.fileList,
          title: this.titleData,
          allNotification: this.allNotification,
        };
        succesMsg = '게시글이 등록되었습니다.';
      }
      if (this.contentData === null || this.contentData.trim() === '') {
        this.alert(['error', '내용을 입력해주세요.']);
        this.progress(false);
      } else {
        this.$socket.emit(command, reqObj, (resp) => {
          if (resp.result === 'success') {
            this.func();
            this.init();
            this.alert(['success', succesMsg]);
          } else {
            this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
            console.error(resp);
          }
          this.progress(false);
        });
      }
    },
  },
  mounted() {
  },
  watch: {
    show: {
      immediate: true,
      handler(val) {
        if (val === true) {
          this.getGroupList();
          this.persistent = true;
          this.titleData = this.title;
          this.contentData = this.content;
        }
      },
    },
    contentData: {
      handler() {
      },
    },
  },
};
</script>
<style scoped lang="scss">
.top-info-wrap {
  display: flex;
  .item + .item {
    margin-left: 10px;
  }
}
.content-wrap {
  padding: 0px 25px;
  .content-row {
    display: flex;
    align-items: center;
  }
}
.files-wrap {
  display: flex;
  flex-wrap: wrap;
  padding: 0px 25px;
  margin-top: 10px;
}
.file-input {
  display: none;
}
.chip-group {
  max-height: 150px;
  overflow: auto;
}
.search-picker-box {
  position: relative;
  z-index: 21;
  .search-picker {
    width: 250px;
    max-width: 250px;
    position: absolute;
    right: 30px;
    top: 8px;
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
      0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
    .v-list {
      max-height: 300px;
      overflow: auto;
    }
    &.search-picker-wide {
      min-width: 300px;
      max-width: 800px;
    }
  }
}
</style>
